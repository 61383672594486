import Web3Status from "components/Web3Status";
import { useIsPoolsPage } from "hooks/useIsPoolsPage";
import { Trans } from "i18n";
import { Box } from "nft/components/Box";
import { Row } from "nft/components/Flex";
import { ReactNode, useState } from "react";
import { useMobile } from "hooks/useMobile";
import {
  NavLink,
  NavLinkProps,
  useLocation,
  useNavigate,
} from "react-router-dom";
import styled from "styled-components";

import { Z_INDEX } from "theme/zIndex";
import Blur from "./Blur";
import { ChainSelector } from "./ChainSelector";
import * as styles from "./style.css";
import { MAIN_SITE, STATIC_RESOURCES } from "../../constants/static";
import Language from "./Language";

const Nav = styled.nav`
  padding: ${({ theme }) => `0 8px`};
  width: 100%;
  height: ${({ theme }) => theme.navHeight}px;
  z-index: ${Z_INDEX.sticky};
  border-bottom: 1px solid #252525;
`;

interface MenuItemProps {
  href: string;
  id?: NavLinkProps["id"];
  isActive?: boolean;
  children: ReactNode;
  dataTestId?: string;
}

const MenuItem = ({
  href,
  dataTestId,
  id,
  isActive,
  children,
}: MenuItemProps) => {
  return (
    <NavLink
      to={href}
      className={isActive ? styles.activeMenuItem : styles.menuItem}
      id={id}
      style={{ textDecoration: "none" }}
      data-testid={dataTestId}
    >
      {children}
    </NavLink>
  );
};

export const PageTabs = () => {
  const { pathname } = useLocation();
  const isPoolActive = useIsPoolsPage();

  return (
    <>
      <MenuItem href="/swap" isActive={pathname.startsWith("/swap")}>
        <Trans>Swap</Trans>
      </MenuItem>
      <MenuItem href={`${MAIN_SITE}earn`} dataTestId="earn-nav-link">
        <Trans>Earn</Trans>
      </MenuItem>
      <MenuItem href={`${MAIN_SITE}launchpad`} dataTestId="launchpad-nav-link">
        <Trans>Launchpad</Trans>
      </MenuItem>
      <MenuItem href={`${MAIN_SITE}marketing`} dataTestId="earn-nav-link">
        <Trans>Marketing</Trans>
      </MenuItem>
      <MenuItem href={`${MAIN_SITE}exchange`} dataTestId="launchpad-nav-link">
        <Trans>Exchange</Trans>
      </MenuItem>
    </>
  );
};

const Navbar = ({ blur }: { blur: boolean }) => {
  const [show, setShow] = useState(false);
  const mobile = useMobile();
  if (mobile) {
    return (
      <>
        <header className="m-header">
          <h1 className="m-header-logo">
            <a href="https://www.rainbowswap.ai">Rainbowswap</a>
          </h1>
          <main>
            <div>
              <Web3Status />
            </div>
            <div className="m-header-menu-icon" onClick={() => setShow(true)}>
              <i></i>
            </div>
          </main>
        </header>
        <section className={`m-header-menu ${show ? "active" : ""}`}>
          <div className="m-header-block">
            <div
              className="m-header-menu-icon active"
              onClick={() => setShow(false)}
            >
              <i></i>
            </div>
          </div>
          <nav className="m-header-nav">
            {/*<Language />*/}
            <a href="/swap" className="active">
              Swap
            </a>
            <a href={`${MAIN_SITE}earn`}>Earn</a>
            <a href={`${MAIN_SITE}launchpad`}>Launchpad</a>
            <a href={`${MAIN_SITE}marketing`}>Marketing</a>
            <a href={`${MAIN_SITE}exchange`}>Exchange</a>
          </nav>
          <ChainSelector />
        </section>
      </>
    );
  }
  return (
    <>
      <header className="web-header">
        <main className="web-header-main">
          <h1 className="web-header-logo">
            <a href="https://www.rainbowswap.ai">Rainbowswap</a>
          </h1>
          {/*<hr className="web-header-line" />*/}
          <nav className="web-header-nav">
            {/*<Language />*/}
            <PageTabs />
          </nav>
        </main>
        <div className="web-header-tools">
          <ChainSelector />
          <hr className="web-header-line" />
          <Web3Status />
        </div>
      </header>
    </>
  );
};

export default Navbar;
