import { useTheme } from "styled-components";

export const TimeForwardIcon = () => {
  const theme = useTheme();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M10.8333 6.66669V9.65497L12.2558 11.0775C12.5817 11.4033 12.5817 11.93 12.2558 12.2559C12.0933 12.4184 11.88 12.5 11.6667 12.5C11.4533 12.5 11.24 12.4184 11.0775 12.2559L9.4108 10.5892C9.25413 10.4325 9.16666 10.2209 9.16666 10V6.66669C9.16666 6.20669 9.53916 5.83335 9.99999 5.83335C10.4608 5.83335 10.8333 6.20669 10.8333 6.66669ZM12.5 5.83335C12.5 6.29335 12.8725 6.66669 13.3333 6.66669H16.6667C17.1275 6.66669 17.5 6.29335 17.5 5.83335V2.50002C17.5 2.04002 17.1275 1.66669 16.6667 1.66669C16.2058 1.66669 15.8333 2.04002 15.8333 2.50002V4.1083C14.2917 2.56496 12.2034 1.66669 9.97842 1.66669C5.39592 1.66669 1.66666 5.40502 1.66666 10C1.66666 14.595 5.39592 18.3334 9.97842 18.3334C13.1643 18.3334 16.0141 16.5583 17.4149 13.7C17.6174 13.2866 17.4474 12.7876 17.0341 12.5851C16.6208 12.3826 16.1208 12.5534 15.9192 12.9667C14.8 15.2492 12.5243 16.6667 9.97842 16.6667C6.31509 16.6667 3.33332 13.6759 3.33332 10C3.33332 6.32419 6.31426 3.33335 9.97842 3.33335C11.6176 3.33335 13.1683 3.93919 14.3658 5.00002H13.3333C12.8725 5.00002 12.5 5.37335 12.5 5.83335Z"
        fill={theme.neutral1}
      />
    </svg>
  );
};
