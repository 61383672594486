export enum SupportArticleURL {
  HOME = "https://support.uniswap.org/hc/en-us",
  HOW_TO_SWAP_TOKENS = "https://support.uniswap.org/hc/en-us/articles/8370549680909-How-to-swap-tokens-",
  WHAT_IS_UNISWAP_X = "https://support.uniswap.org/hc/en-us/articles/17515415311501",
  UNSUPPORTED_TOKEN_AND_NFT_POLICY = "https://support.uniswap.org/hc/en-us/articles/18783694078989-Unsupported-Token-Policy",
  WETH_EXPLAINER = "https://support.uniswap.org/hc/en-us/articles/16015852009997-Why-do-ETH-swaps-involve-converting-to-WETH",
  APPROVALS_EXPLAINER = "https://support.uniswap.org/hc/en-us/articles/8120520483085-What-is-an-approval-transaction",
  TRANSACTION_FAILURE = "https://support.uniswap.org/hc/en-us/articles/8643975058829-Why-did-my-transaction-fail-",
  UNISWAP_X_FAILURE = "https://support.uniswap.org/hc/en-us/articles/17515489874189-Why-can-my-swap-not-be-filled-",
  MOONPAY_REGIONAL_AVAILABILITY = "https://support.uniswap.org/hc/en-us/articles/11306664890381-Why-isn-t-MoonPay-available-in-my-region-",
  LEARN_ABOUT_LIMITS = "https://support.uniswap.org/hc/en-us/sections/24372644881293",
  LIMIT_FAILURE = "https://support.uniswap.org/hc/en-us/articles/24300813697933-Why-did-my-limit-order-fail-or-not-execute",
  IMPERMANENT_LOSS = "https://support.uniswap.org/hc/en-us/articles/20904453751693-What-is-Impermanent-Loss",
}
