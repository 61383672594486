import {
  BrowserEvent,
  InterfaceEventName,
  InterfaceModalName,
} from "@uniswap/analytics-events";
import { Trace, TraceEvent } from "analytics";
import { ScrollBarStyles } from "components/Common";
import useDisableScrolling from "hooks/useDisableScrolling";
import usePrevious from "hooks/usePrevious";
import { useWindowSize } from "hooks/useWindowSize";
import { RefObject, useEffect, useRef, useState } from "react";
import { ChevronsRight } from "react-feather";
import { useGesture } from "react-use-gesture";
import styled from "styled-components";
import { BREAKPOINTS } from "theme";
import { ClickableStyle, ThemedText } from "theme/components";
import { Z_INDEX } from "theme/zIndex";
import { isMobile } from "uniswap/src/utils/platform";

import DefaultMenu from "./DefaultMenu";
import { useAccountDrawer, useCloseAccountDrawer } from "./MiniPortfolio/hooks";
import Modal from "../Modal";
import { PaddedColumn, SearchInput, Separator } from "../SearchModal/styled";
import Row, { RowBetween } from "../Row";
import { Text } from "rebass";
import { t, Trans } from "../../i18n";
import CommonBases from "../SearchModal/CommonBases";
import Column from "../Column";
import CurrencyList, {
  CurrencyRow,
  formatAnalyticsEventProperties,
} from "../SearchModal/CurrencyList";
import tryParseCurrencyAmount from "../../lib/utils/tryParseCurrencyAmount";
import { CurrencyAmount } from "@uniswap/sdk-core";
import AutoSizer from "react-virtualized-auto-sizer";

const DRAWER_WIDTH_XL = "390px";
const DRAWER_WIDTH = "320px";
const DRAWER_MARGIN = "0px";
const DRAWER_OFFSET = "10px";
const DRAWER_TOP_MARGIN_MOBILE_WEB = "24px";

const ScrimBackground = styled.div<{
  $open: boolean;
  $maxWidth?: number;
  $zIndex?: number;
}>`
  z-index: ${({ $zIndex }) => $zIndex ?? Z_INDEX.modalBackdrop};
  overflow: hidden;
  top: 0;
  left: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.scrim};

  opacity: 0;
  pointer-events: none;
  @media only screen and (max-width: ${({ theme, $maxWidth }) =>
      `${$maxWidth ?? theme.breakpoint.sm}px`}) {
    opacity: ${({ $open }) => ($open ? 1 : 0)};
    pointer-events: ${({ $open }) => ($open ? "auto" : "none")};
    transition: opacity ${({ theme }) => theme.transition.duration.medium}
      ease-in-out;
  }
`;

interface ScrimBackgroundProps extends React.ComponentPropsWithRef<"div"> {
  $open: boolean;
  $maxWidth?: number;
  $zIndex?: number;
}

export const Scrim = (props: ScrimBackgroundProps) => {
  const { width } = useWindowSize();

  useEffect(() => {
    if (width && width < BREAKPOINTS.sm && props.$open)
      document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "visible";
    };
  }, [props.$open, width]);

  return <ScrimBackground {...props} />;
};

const AccountDrawerScrollWrapper = styled.div`
  overflow-y: auto;
  overflow-x: hidden;

  ${ScrollBarStyles}

  scrollbar-gutter: stable;
  overscroll-behavior: contain;
  border-radius: 12px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: calc(100% - 2 * ${DRAWER_MARGIN});
  overflow: hidden;
  position: fixed;
  right: ${DRAWER_MARGIN};
  top: ${DRAWER_MARGIN};
  z-index: ${Z_INDEX.fixed};

  @media only screen and (max-width: ${({ theme }) =>
      `${theme.breakpoint.sm}px`}) {
    top: 100vh;
    left: 0;
    right: 0;
    width: 100%;
    overflow: visible;
  }
`;

const AccountDrawerWrapper = styled.div<{ open: boolean }>`
  margin-right: ${({ open }) => (open ? 0 : "-" + DRAWER_WIDTH)};
  height: 100%;
  overflow: hidden;

  @media only screen and (max-width: ${({ theme }) =>
      `${theme.breakpoint.sm}px`}) {
    z-index: ${Z_INDEX.modal};
    position: absolute;
    margin-right: 0;
    top: ${({ open }) =>
      open ? `calc(-1 * (100% - ${DRAWER_TOP_MARGIN_MOBILE_WEB}))` : 0};
    height: calc(100% - ${DRAWER_TOP_MARGIN_MOBILE_WEB});

    width: 100%;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    box-shadow: unset;
    transition: top ${({ theme }) => theme.transition.duration.medium};
  }

  @media screen and (min-width: 1440px) {
    margin-right: ${({ open }) => (open ? 0 : `-${DRAWER_WIDTH_XL}`)};
    width: ${DRAWER_WIDTH_XL};
    max-width: ${DRAWER_WIDTH_XL};
  }

  width: ${DRAWER_WIDTH};
  max-width: ${DRAWER_WIDTH};
  font-size: 16px;
  background-color: ${({ theme }) => theme.surface1};
  border-left: ${({ theme }) => `2px solid ${theme.surface3}`};

  box-shadow: ${({ theme }) => theme.deprecated_deepShadow};
  transition: margin-right ${({ theme }) => theme.transition.duration.medium};
`;

const CloseIcon = styled(ChevronsRight).attrs({ size: 24 })`
  stroke: ${({ theme }) => theme.neutral2};
`;

const CloseDrawer = styled.div`
  cursor: pointer;
  text-align: center;
  // When the drawer is not hovered, the icon should be 18px from the edge of the sidebar.
  padding: 10px;
  transition: ${({ theme }) =>
    `${theme.transition.duration.medium} ${theme.transition.timing.ease} background-color, ${theme.transition.duration.medium} ${theme.transition.timing.ease} margin`};
  background-color: ${({ theme }) => theme.deprecated_stateOverlayHover};

  &:hover {
    z-index: -1;
    margin: 0 -8px 0 0;
  }

  @media only screen and (max-width: ${({ theme }) =>
      `${theme.breakpoint.sm}px`}) {
    display: none;
  }
`;

const ContentWrapper = styled(Column)`
  background-color: ${({ theme }) => theme.surface2};
  width: 100%;
  overflow: hidden;
  flex: 1 1;
  position: relative;
  border-radius: 12px;
`;

function AccountDrawer() {
  const [walletDrawerOpen] = useAccountDrawer();
  const closeAccountDrawer = useCloseAccountDrawer();

  return (
    <Modal isOpen={walletDrawerOpen} onDismiss={closeAccountDrawer}>
      <DefaultMenu drawerOpen={walletDrawerOpen} />
    </Modal>
  );
}

export default AccountDrawer;
