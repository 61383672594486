import { ChainId, Currency } from "@uniswap/sdk-core";
import blankTokenUrl from "assets/svg/blank_token.svg";
import { ReactComponent as UnknownStatus } from "assets/svg/contract-interaction.svg";
import { MissingImageLogo } from "components/Logo/AssetLogo";
import { ChainLogo, getDefaultBorderRadius } from "components/Logo/ChainLogo";
import { Unicon } from "components/Unicon";
import { useCurrencyInfo } from "hooks/Tokens";
import useENSAvatar from "hooks/useENSAvatar";
import React, { useCallback, useState } from "react";
import { Loader } from "react-feather";
import styled from "styled-components";
import { useIsDarkMode } from "theme/components/ThemeToggle";
import { UniconV2 } from "ui/src/components/UniconV2";
import { useLogolessColorScheme } from "ui/src/utils/colors";
import { FeatureFlags } from "uniswap/src/features/statsig/flags";
import { useFeatureFlag } from "uniswap/src/features/statsig/hooks";
import { STATIC_RESOURCES } from "../../../constants/static";

const UnknownContract = styled(UnknownStatus)`
  color: ${({ theme }) => theme.neutral2};
`;

const DoubleLogoContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2px;
  position: relative;
  top: 0;
  left: 0;
  img:nth-child(n) {
    width: 19px;
    height: 40px;
    object-fit: cover;
  }
  img:nth-child(1) {
    border-radius: 20px 0 0 20px;
    object-position: 0 0;
  }
  img:nth-child(2) {
    border-radius: 0 20px 20px 0;
    object-position: 100% 0;
  }
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  top: 0;
  left: 0;
`;

const ENSAvatarImg = styled.img`
  border-radius: 8px;
  height: 40px;
  width: 40px;
`;

const CircleLogoImage = styled.img<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  border-radius: 50%;
`;

const L2LogoContainer = styled.div`
  position: absolute;
  border-radius: ${getDefaultBorderRadius(16)}px;
  left: 65%;
  top: 65%;
  outline: 1.5px solid ${({ theme }) => theme.surface1};
  width: 40%;
  height: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface DoubleLogoProps {
  logo1?: string;
  logo2?: string;
  size: string;
  onError1?: () => void;
  onError2?: () => void;
}

function DoubleLogo({
  logo1,
  onError1,
  logo2,
  onError2,
  size,
}: DoubleLogoProps) {
  return (
    <DoubleLogoContainer>
      <CircleLogoImage
        size={size}
        src={logo1 ?? blankTokenUrl}
        onError={onError1}
      />
      <CircleLogoImage
        size={size}
        src={logo2 ?? blankTokenUrl}
        onError={onError2}
      />
    </DoubleLogoContainer>
  );
}

interface DoubleCurrencyLogoProps {
  chainId: ChainId;
  currencies: Array<Currency | undefined>;
  backupImages?: Array<string | undefined>;
  size: string;
}

function DoubleCurrencyLogo({
  currencies,
  size,
  backupImages,
}: DoubleCurrencyLogoProps) {
  const [imgError, setImgError] = useState<boolean>(false)

  const currencyInfos = [
    useCurrencyInfo(currencies?.[0]),
    useCurrencyInfo(currencies?.[1]),
  ];
  if (currencies.length === 1 && currencyInfos[0]?.logoUrl) {
    return <CircleLogoImage size={size} src={currencyInfos[0].logoUrl} />;
  }
  const logo1 = currencyInfos[0]?.logoUrl ?? backupImages?.[0];
  const logo2 = currencyInfos[1]?.logoUrl ?? backupImages?.[1];
  if (currencies.length > 1 && (logo1 || logo2)) {
    return <DoubleLogo logo1={logo1} logo2={logo2} size={size} />;
  }
  if (!imgError) {
    return <CircleLogoImage size={size} src={`${STATIC_RESOURCES}tokens/${currencies?.[0]?.symbol?.toLowerCase()}.png`} onError={() => setImgError(true)} />;
  } else {
    return <LogolessPlaceholder currency={currencies?.[0]} size={size} />;
  }
}

function LogolessPlaceholder({
  currency,
  size,
}: {
  currency?: Currency;
  size: string;
}) {
  const isDarkMode = useIsDarkMode();
  const logolessColorScheme = useLogolessColorScheme(
    currency?.name ?? currency?.symbol ?? ""
  );
  const { foreground, background } = isDarkMode
    ? logolessColorScheme.dark
    : logolessColorScheme.light;

  return (
    <MissingImageLogo
      $size={size}
      $textColor={foreground}
      $backgroundColor={background}
    >
      {currency?.symbol
        ?.toUpperCase()
        .replace("$", "")
        .replace(/\s+/g, "")
        .slice(0, 3)}
    </MissingImageLogo>
  );
}

function PortfolioAvatar({
  accountAddress,
  size,
}: {
  accountAddress: string;
  size: string;
}) {
  const { avatar, loading } = useENSAvatar(accountAddress, false);
  const uniconV2Enabled = useFeatureFlag(FeatureFlags.UniconsV2);

  if (loading) {
    return <Loader size={size} />;
  }
  if (avatar) {
    return <ENSAvatarImg src={avatar} alt="avatar" />;
  }
  return (
    <>
      {uniconV2Enabled ? (
        <UniconV2 address={accountAddress} size={40} />
      ) : (
        <Unicon address={accountAddress} size={40} />
      )}
    </>
  );
}

interface PortfolioLogoProps {
  chainId: ChainId;
  accountAddress?: string;
  currencies?: Array<Currency | undefined>;
  images?: Array<string | undefined>;
  size?: string;
  style?: React.CSSProperties;
}

function SquareL2Logo({ chainId }: { chainId: ChainId }) {
  if (chainId === ChainId.MAINNET) return null;

  return (
    <L2LogoContainer>
      <ChainLogo fillContainer={true} chainId={chainId} />
    </L2LogoContainer>
  );
}

// TODO(WEB-2983)
/**
 * Renders an image by prioritizing a list of sources, and then eventually a fallback contract icon
 */
export function PortfolioLogo(props: PortfolioLogoProps) {
  return (
    <LogoContainer style={props.style}>
      {getLogo(props)}
      {/*<SquareL2Logo chainId={props.chainId} />*/}
    </LogoContainer>
  );
}

function getLogo({
  chainId,
  accountAddress,
  currencies,
  images,
  size = "40px",
}: PortfolioLogoProps) {
  if (accountAddress) {
    return <PortfolioAvatar accountAddress={accountAddress} size={size} />;
  }
  if (currencies && currencies.length) {
    return (
      <DoubleCurrencyLogo
        chainId={chainId}
        currencies={currencies}
        size={size}
      />
    );
  }
  if (images?.length === 1) {
    return <CircleLogoImage size={size} src={images[0] ?? blankTokenUrl} />;
  }
  if (images && images?.length >= 2) {
    return (
      <DoubleLogo
        logo1={images[0]}
        logo2={images[images.length - 1]}
        size={size}
      />
    );
  }
  return <UnknownContract width={size} height={size} />;
}
