import {
  BrowserEvent,
  InterfaceElementName,
  SharedEventName,
} from "@uniswap/analytics-events";
import { useWeb3React } from "@web3-react/core";
import { TraceEvent } from "analytics";
import { Power } from "components/Icons/Power";
import { getConnection } from "connection";
import useENSName from "hooks/useENSName";
import { useCallback, useState } from "react";
import { useAppDispatch } from "state/hooks";
import { setRecentConnectionDisconnected } from "state/user/reducer";
import styled from "styled-components";
import { useUnitagByAddressWithoutFlag } from "uniswap/src/features/unitags/hooksWithoutFlags";
import { IconHoverText, IconWithConfirmTextButton } from "./IconButton";
import { Status } from "./Status";
import { AutoRow } from "../Row";
import { CloseIcon, ThemedText } from "../../theme/components";
import { useCloseAccountDrawer } from "./MiniPortfolio/hooks";

const AuthenticatedHeaderWrapper = styled.div`
  padding: 20px 16px;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const IconContainer = styled.div`
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  & > a,
  & > button {
    margin-right: 8px;
  }

  & > button:last-child {
    margin-right: 0px;
    ${IconHoverText}:last-child {
      left: 0px;
    }
  }
`;

const HeaderWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

export default function AuthenticatedHeader({
  account,
  openSettings,
}: {
  account: string;
  openSettings: () => void;
}) {
  const { connector } = useWeb3React();
  const { ENSName } = useENSName(account);
  const dispatch = useAppDispatch();
  const closeAccountDrawer = useCloseAccountDrawer();
  const connection = getConnection(connector);
  const disconnect = useCallback(() => {
    closeAccountDrawer();
    connector.deactivate?.();
    connector.resetState();
    dispatch(setRecentConnectionDisconnected());
  }, [connector, dispatch]);

  const [showDisconnectConfirm, setShowDisconnectConfirm] = useState(false);

  const { unitag } = useUnitagByAddressWithoutFlag(account, Boolean(account));

  return (
    <AuthenticatedHeaderWrapper>
      <AutoRow justify="space-between" width="100%">
        <ThemedText.SubHeader>Your wallet</ThemedText.SubHeader>
        <CloseIcon onClick={closeAccountDrawer} />
      </AutoRow>
      <HeaderWrapper>
        <Status
          account={account}
          ensUsername={ENSName}
          uniswapUsername={unitag?.username}
          connection={connection}
        />
        <IconContainer>
          <TraceEvent
            events={[BrowserEvent.onClick]}
            name={SharedEventName.ELEMENT_CLICKED}
            element={InterfaceElementName.DISCONNECT_WALLET_BUTTON}
          >
            <IconWithConfirmTextButton
              data-testid="wallet-disconnect"
              onConfirm={disconnect}
              onShowConfirm={setShowDisconnectConfirm}
              Icon={Power}
              text="Disconnect"
              dismissOnHoverOut
            />
          </TraceEvent>
        </IconContainer>
      </HeaderWrapper>
    </AuthenticatedHeaderWrapper>
  );
}
