import { useWeb3React } from "@web3-react/core";
import IconButton from "components/AccountDrawer/IconButton";
import {
  useCloseAccountDrawer,
  useShowMoonpayText,
} from "components/AccountDrawer/MiniPortfolio/hooks";
import Column from "components/Column";
import { Settings } from "components/Icons/Settings";
import Row, { AutoRow } from "components/Row";
import { networkConnection } from "connection";
import { ActivationStatus, useActivationState } from "connection/activate";
import { isSupportedChain } from "constants/chains";
import { useUniswapWalletOptions } from "hooks/useUniswapWalletOptions";
import { Trans } from "i18n";
import { useEffect } from "react";
import styled from "styled-components";
import { CloseIcon, ExternalLink, ThemedText } from "theme/components";
import { flexColumnNoWrap } from "theme/styles";
import { Text } from "ui/src/components/text/Text";
import ConnectionErrorView from "./ConnectionErrorView";
import { DeprecatedInjectorMessage } from "./Option";
import PrivacyPolicyNotice from "./PrivacyPolicyNotice";
import { UniswapWalletOptions } from "./UniswapWalletOptions";
import { useOrderedConnections } from "./useOrderedConnections";
import { LightCard } from "../Card";

const Wrapper = styled.div`
  ${flexColumnNoWrap};
  background-color: ${({ theme }) => theme.surface1};
  width: 100%;
  padding: 20px;
  flex: 1;
  gap: 16px;
`;

const OptionGrid = styled.div`
  display: grid;
  flex: 1;
  grid-gap: 8px;
  overflow: hidden;
  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToMedium`
    grid-template-columns: 1fr;
  `};
`;

export default function WalletModal({
  openSettings,
}: {
  openSettings: () => void;
}) {
  const { connector, chainId } = useWeb3React();
  const closeAccountDrawer = useCloseAccountDrawer();
  const { activationState } = useActivationState();
  // Keep the network connector in sync with any active user connector to prevent chain-switching on wallet disconnection.
  useEffect(() => {
    if (
      chainId &&
      isSupportedChain(chainId) &&
      connector !== networkConnection.connector
    ) {
      networkConnection.connector.activate(chainId);
    }
  }, [chainId, connector]);

  const showUniswapWalletOptions = useUniswapWalletOptions();

  const { orderedConnections, showDeprecatedMessage } = useOrderedConnections(
    !!showUniswapWalletOptions
  );

  return (
    <Wrapper data-testid="wallet-modal">
      <AutoRow justify="space-between" width="100%">
        <ThemedText.SubHeader>Connect a wallet</ThemedText.SubHeader>
        <CloseIcon onClick={closeAccountDrawer} />
      </AutoRow>
      {activationState.status === ActivationStatus.ERROR ? (
        <ConnectionErrorView />
      ) : (
        <Column gap="md" flex="1">
          <Row flex="1" align="flex-start">
            <OptionGrid data-testid="option-grid">
              {orderedConnections}
            </OptionGrid>
          </Row>
        </Column>
      )}
      {/*<ThemedText.BodySecondary fontSize={14} lineHeight={1.4}>*/}
      {/*  <Trans>*/}
      {/*    By connecting a wallet, you agree to RainbowSWAP Labs’{' '}*/}
      {/*    <ExternalLink href="">Terms of Service</ExternalLink> and acknowledge that you have read and*/}
      {/*    understand the RainbowSWAP <ExternalLink href="">Protocol Disclaimer</ExternalLink>.*/}
      {/*  </Trans>*/}
      {/*</ThemedText.BodySecondary>*/}
    </Wrapper>
  );
}
